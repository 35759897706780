.card_class::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.card_class::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  background-color: #671e78 !important;
}

.card_class::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  display: block !important;
  visibility: visible !important;
  /* background-color: green !important; */
}

.sheet_table {
  border: 2px solid black;
  border-radius: 10px;
  font-family: "Gilroy-Regular";
}
.sheet_table::-webkit-scrollbar {
  display: none;
}
.sheet_table table {
  border-collapse: collapse;
}
.sheet_table tr:first-child th {
  border-bottom: 2px solid black;
  border-collapse: collapse;
  font-size: 12px;
  border-right: 2px solid black;
  /* height: 142px; */
}

.sheet_table th:last-child {
  border-right: none;
}
.sheet_table tr {
  border-bottom: 2px solid black;
  border-collapse: collapse;
  background-color: #fff !important;
}
.sheet_table td {
  border: 2px solid black;
  border-collapse: collapse;
  text-align: center;
  font-size: 12px;
  padding: 8px 0px;
}
.sheet_table td:first-child {
  border-left: 0px solid black;
}
.sheet_table td:last-child {
  border: none;
}
/* 
.tnh_1 {
  position: absolute;
  width: fit-content;
  transform: rotate(-90deg);
  top: 52%;
  left: -82%;
  transform-origin: top;
  white-space: nowrap;
}
.tnh_2 {
  position: absolute;
  width: fit-content;
  transform: rotate(-90deg);
  top: 52%;
  left: -56%;
  transform-origin: top;
  white-space: nowrap;
}
.tnh_3 {
  position: absolute;
  width: fit-content;
  transform: rotate(-90deg);
  top: 52%;
  left: -56%;
  transform-origin: top;
  white-space: nowrap;
}
.tnh_4 {
  position: absolute;
  width: fit-content;
  transform: rotate(-90deg);
  top: 52%;
  left: -56%;
  transform-origin: top;
  white-space: nowrap;
}
.tnh_5 {
  position: absolute;
  width: fit-content;
  transform: rotate(-90deg);
  top: 52%;
  left: -56%;
  transform-origin: top;
  white-space: nowrap;
}
.tnh_6 {
  position: absolute;
  width: fit-content;
  transform: rotate(-90deg);
  top: 52%;
  left: -56%;
  transform-origin: top;
  white-space: nowrap;
} */

.transform_head {
  /* transform: rotate(-66deg); */
  width: 58px;
}
/* 
.transform_head > div {
  width: max-content;
} */

@media (max-width: 588px) {
  .sheet_table th {
    font-size: 10px;
    padding: 5px;
  }
}

.result_table th {
  font-size: 13px;
  color: #000;
}
.result_table td {
  font-size: 12px;
  padding-left: 10px;
  color: #000;
}

.fourth-child {
  border-right: 2px solid red !important;
}
